export default function numberFormatter(input: number, round = false, options?: Intl.NumberFormatOptions) {
  const formatter = new Intl.NumberFormat('en-US', {
    ...options
  })

  if (round) {
    return formatter.format(Math.round(input))
  }

  return formatter.format(input)
}
